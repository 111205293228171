 <template>
  <div id="inconWorkEstablish">
    <van-cell-group tilte="项目信息"> 
      <van-field
        v-model="sampleForm.sampleTypeName"
        label="样品分类"
        readonly
        clickable
        name="picker"
        placeholder="点击选择样品分类"
        @click="showSpecPicker = true"
        right-icon="arrow"
        input-align="right"
        required
      />
    </van-cell-group>

  </div>
 </template>
  